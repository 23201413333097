body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

.font-12 {
  font-size: 12px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.card-border-setting .Polaris-Card__Header h2 {
  padding-bottom: 16px;
}

.card-border-setting .Polaris-Card__Header {
  border-bottom: 1px solid #e1e3e5;
}

.card-border-setting {
  margin-top: 8px;
}

.stock-question {
  padding: 16px 0;
}
.paddingi-inline-block {
  padding: 16px;
}
.stock-note {
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.product-img-badge {
  position: relative;
}

.preorder-badge-ribbon {
  position: absolute;
  right: 13px;
  top: 0;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.product-img-badge img {
  height: 182.43px;
}

.preorder-badge-ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
}

.preorder-badge-rectangle {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 10px;
  width: 25%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  overflow: hidden;
}

.preorder-badge-circle {
  display: table;
  position: absolute;
  right: 18px;
  top: 3px;
  padding: 5px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  overflow: hidden;
}

.preorder-badge-circle span {
  display: table-cell;
  vertical-align: middle;
}

.stock-warning-color {
  color: #6d7175;
}

.Custom-allproduct-chechbox {
  padding-top: 16px;
}

.custom-setting_page-right-header-title {
  color: #202223;
  font-weight: 400;
  line-height: 20px;
}

.custom-input-style .Polaris-TextField__Suffix {
  border-left: 1px solid #a9aeb3;
  padding-left: 12px;
  padding-top: 4px;
  color: black;
  height: 35px;
}

.custom-input-style1 .Polaris-TextField__Suffix {
  padding-left: 5px;
  margin-right: 10px;
  color: black;
}

.custom-input-style1 .Polaris-TextField input {
  border-right: 1px solid #a9aeb3;
}

.custom-input-style1 .Polaris-TextField__Suffix .Polaris-TextField__Input {
  width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  padding: 0;
}

.custom-input-style-setting .Polaris-TextField input {
  border-left: 1px solid #a9aeb3;
  padding-left: 10px;
}

.custom-input-style-setting .Polaris-TextField__Prefix {
  width: 15px;
  padding-left: 3px;
}

.custom-input-style1
  .Polaris-TextField__Input:focus
  ~ .Polaris-TextField__Backdrop::after {
  box-shadow: unset;
  outline: unset;
}

.custom-name {
  display: flex;
}

#main {
  display: block;
}

.custom-single-product-header-title {
  padding: 10px;
  padding-left: 20px;
  font-weight: 500;
}

.custom-single-product-header-title-Product {
  font-size: 14px;
  padding-left: 20px;
  padding: 20px 0px 20px 20px;
  font-weight: 500;
}

.custom-single-product-header-imagetitle {
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
}

.custom-single-product-header-title-Variant_ul .polaris-tabs-stylish {
  border-top: 1px solid rgb(235 231 231) !important;
}

.custom-single-product-header-title-Variant_ul .tab-pp {
  display: flex;
  justify-content: space-between;
}

.custom-single-product-header-title-Variant_ul .header {
  padding: 13px;
  /* border-top: 1px solid rgb(235 231 231) !important; */
}

.custom-single-product-header-title-Variant_ul .header .active {
  border: none !important;
  /* color: rgba(32, 34, 35, 1) !important; */
}

.custom-single-product-header-title-Variant_ul {
  padding: 0px;
  border-radius: inherit;
}

.custom-single-product-header-title-Variant_ul li {
  list-style-type: none !important;
  padding: 9px;
  border-top: 1px solid rgb(235 231 231);
}

.custom-single-product-header-title-Variant_ul li span {
  padding-left: 20px;
  cursor: pointer;
}

.custom-single-product-header-title-Variant {
  font-size: 14px;
  padding-left: 20px;
  padding: 5px 0px 5px 20px;
  font-weight: 500;
}

.enable-title {
  padding-left: 20px;
}

.product-table tr {
  border-bottom: 1px solid #e1e3e5;
}

.product-table .table {
  padding: 0 !important;
}

.product-table .table th,
td {
  /* text-align: center; */
  padding: 16px 10px 16px 15px;
  /* padding: 16px 12px; */
}

.product-table .table td {
  text-align: center;
  padding: 16px 12px !important;
}

.back-button .Polaris-Button {
  height: 36px;
  width: 36px;
}

.back-button .Polaris-Icon {
  height: 12px;
  width: 16px;
}

.rotate .Polaris-Icon__Svg {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate-down .Polaris-Icon__Svg {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);

  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.Advance-setting .Polaris-Page__Content {
  padding-top: 0;
}

.install-uninstall-setting .Polaris-Page__Content {
  padding-top: 0;
}

.Savebtn .Polaris-Page__Content {
  padding-top: 0 !important;
}

.Savebtn .Polaris-Page {
  padding-right: 36px;
}

.email-content-helptext {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7175;
}

.char-count .Polaris-TextField__CharacterCount {
  position: absolute !important;
  right: -10px !important;
  top: 40px !important;
}

/** css for email content */
.email-editor iframe {
  min-width: 100% !important;
}

.page-not-found-content {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index_tbl_row {
  text-align: center !important;
}

.preorder_pro_heading {
  font-weight: 600;
}

.single-product-pagination nav div {
  justify-content: center;
}

.single-product-pagination nav {
  padding: 10px;
}

.no-data-found {
  width: 100%;
  text-align: center;
}

.pre-order-table
  .Polaris-IndexTable
  .Polaris-IndexTable-ScrollContainer
  table
  tbody
  tr
  td.Polaris-IndexTable__TableCell:nth-child(2)
  span {
  margin-top: 10px;
}

.pre-order-table
  .Polaris-IndexTable
  .Polaris-IndexTable-ScrollContainer
  table
  tbody
  tr
  td.Polaris-IndexTable__TableCell:nth-child(2) {
  white-space: initial !important;
  display: flex;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th {
  background: none;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th.Polaris-IndexTable__TableHeading--first {
  padding-left: 0;
  text-align: center;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th.Polaris-IndexTable__TableHeading--first
  .Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: block;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th,
.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  tbody
  tr
  td {
  padding: 15px;
}

.pre-order-table
  .Polaris-IndexTable
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th.Polaris-IndexTable__TableHeading {
  background: none;
}

table thead tr th.Polaris-DataTable__Cell:nth-child(2) .preorder_pro_heading {
  text-align: center;
}

.edittag .Polaris-Button--plain svg {
  fill: #5c5f62 !important;
}

.edittag {
  padding-right: 20px;
}

.preorder_btn {
  width: 50%;
  letter-spacing: 0.1rem;
}

.theme_install {
  text-decoration: none;
}

.w-50 {
  width: 50%;
}

.inventory {
}

.badge_shape {
  background: linear-gradient(#fb02ce 0%, #8679d8 100%);
  box-shadow: rgb(0, 0, 0) 10px 3px 10px -5px;
}

.edit_pro {
  padding: 0px 0px 12px 0px;
}

.form_selector {
  padding-right: 310px;
}

.product_selector {
  padding-right: 155px;
}

.add_to_cart_select {
  padding-right: 230px;
}

.nav-bar {
  cursor: pointer;
}

.pricing_plan {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.order_type {
  text-align: center;
}

.edit_btn .Polaris-Button {
  padding: 0;
}

.border-top {
  border-top: none !important;
}

.border-top-edit .Polaris-Card__Header {
  border-bottom: 1px solid #e5e3e3;
  padding-bottom: 17px;
}

.pre_email {
  padding-left: 12px;
}

.preorder_list {
  padding-top: 10px;
}
/* 
.progress_bar {
  width: 85%;
}

.step_width {
  width: 15%;
} */

.onb_svg {
  margin-right: 15px;
  margin-top: 3px;
}

.ml-1 {
  margin-left: 0.3rem;
}

/*new*/
.onb_body .Polaris-LegacyCard__Section:nth-child(2) {
  padding: 0;
  width: 100%;
}

.react-tabs {
  display: flex;
  width: 100%;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  width: 25%;
  background-color: #fafbfb;
}

.react-tabs__tab {
  border-bottom: 1px solid #dfe3e8;
  list-style: none;
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.react-tabs__tab--selected {
  border-left: 4px solid #303030;
}

.react-tabs__tab--completed {
  border-left: 0px solid #303030;
  font-weight: 700;
}

.react-tabs__tab-panel {
  display: none;
  width: 75%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
  outline: none;
}

.ml-1 {
  margin-left: 0.3rem;
}

.panel-content {
  padding: 20px;
}

.react-tabs__tab--selected,
.react-tabs__tab--selected svg path {
  background-color: #FFFFFF;
  color: #303030;
  fill: #303030;
}

.react-tabs__tab--completed,
.react-tabs__tab--completed svg path{
  background-color: #fafbfb;
  color: #303030;
  fill: #303030;
}

.redirect_to_theme a {
  color: #000;
  text-decoration: none;
}

.next_step {
  text-align: right;
}

/*new end*/
.product_variant_name {
  cursor: pointer;
}

.variant_active {
  box-shadow: inset 0.125rem 0 0 #303030;
  background-color: #efefef;
}

/* setting page new design*/
.settings-page {
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
}

.setting-page-item {
  padding: 1em 1em 1em 1em;
}

.setting-page-item:hover {
  background: #f6f6f7;
  border-radius: 5px;
}

.settings-page .setting-page-item .icon {
  display: flex;
  background: #ffffff;
  border: 1px solid #ebebeb;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: transparent;
  border-radius: 8px;
}

.settings-page .setting-page-item .icon svg {
  fill: #919eab;
}

.setting_text {
  color: #006fbb;
}

.setting-page-item .Polaris-Link--removeUnderline:hover {
  text-decoration: none;
}

.p-20 {
  padding: 20px !important;
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: middle !important;
}

.Polaris-Select select {
  padding: 0 0 0 10px;
}

.best_seller
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th {
  background: none;
}

.best_seller
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th,
.best_seller
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  .Polaris-IndexTable-ScrollContainer
  table
  tbody
  tr
  td {
  padding: 15px;
}

.preorder_btn {
  margin-bottom: 10px !important;
}

.start_date .Polaris-TextField--readOnly > .Polaris-TextField__Backdrop,
.end_date .Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
  background-color: #fff !important;
}

.email-editor .Polaris-Label {
  margin-bottom: 10px;
}

.last_col {
  text-align: right;
}

@media (max-width: 768px) {
  .status-block {
    margin-top: 10px;
    margin-left: 20px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Polaris-IndexTable__TableHeading {
  padding: var(--p-space-2) var(--p-space-4);
  color: black;
  font-size: initial;
  white-space: nowrap;
  border: 0;
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableHeading--second:not(
      .Polaris-IndexTable__TableHeading--unselectable
    ) {
    position: absolute;
    z-index: auto;
    left: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-IndexTable__Table--sticky
    .Polaris-IndexTable__TableHeading--last:not(
      .Polaris-IndexTable__TableHeading--unselectable
    ) {
    /* position: absolute; */
    /* justify-content: end; */
    right: 0;
    background-color: var(--p-surface);
    z-index: auto;
  }
}

.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  position: absolute;
  z-index: auto;
  left: 0;
}

.Polaris-IndexTable__TableCell {
  white-space: unset;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable-ScrollContainer
  table
  tbody
  tr
  td
  .Polaris-IndexTable-Checkbox__TableCellContentContainer
  .Polaris-IndexTable-Checkbox--expanded {
  padding-left: 0;
  justify-content: left;
}

.action_edit {
  text-align: end !important;
}

.tag_list
  .Polaris-IndexTable
  .Polaris-IndexTable-ScrollContainer
  table
  thead
  tr
  th:last-child {
  text-align: end;
}

.display-flex {
  display: flex;
}

.product-img-size img,
.product-img-badge > img {
  width: 100%;
  height: 100%;
}

.product-edit-img {
  width: 80px;
  height: 80px;
  border: 1px solid #dde0e4;
  border-radius: 6px;
}

.product-edit-img > img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.product-edit-variant-detail
  > .Polaris-LegacyStack
  > .Polaris-LegacyStack__Item {
  flex: 1;
}

.pre-setting-layout
  > .Polaris-Layout__AnnotatedSection
  > .Polaris-Layout__AnnotationWrapper
  > .Polaris-Layout__Annotation {
  margin-top: 0;
}

.pre-setting-layout
  > .Polaris-Layout__AnnotatedSection
  > .Polaris-Layout__AnnotationWrapper
  > .Polaris-Layout__Annotation,
.pre-setting-layout
  > .Polaris-Layout__AnnotatedSection
  > .Polaris-Layout__AnnotationWrapper
  > .Polaris-Layout__AnnotationContent {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.pre-reponsive-flex > .Polaris-Grid {
  display: flex;
  justify-content: space-between;
}

.pre-order-pro-reponsive
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  table.Polaris-IndexTable__Table
  thead
  tr
  th:nth-child(1) {
    padding: 10px;
  background-color: #fff;
  left: 2px;
}

@media (max-width: 767px) {
  .product-edit-variant-detail > .Polaris-LegacyStack,
  .mob-flex-column {
    flex-direction: column;
  }

  .product-edit-variant-detail
    > .Polaris-LegacyStack
    > .Polaris-LegacyStack__Item:nth-last-child(1) {
    width: 100%;
  }

  .mob-polaris-card,
  .product-img-badge > img {
    width: 100%;
  }

  .mob-polaris-card .mob-d-flex > .Polaris-Grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .mob-pt-1 {
    padding-top: 1rem;
  }

  .Polaris-LegacyCard__Section > .settings-page {
    display: flex;
    flex-direction: column;
  }

  .pre-order-pro-reponsive
    .Polaris-IndexTable
    .Polaris-IndexTable__IndexTableWrapper
    table.Polaris-IndexTable__Table
    thead
    tr
    th:nth-child(3) {
    min-width: 150px;
  }

  .pre-order-pro-reponsive
    .Polaris-IndexTable
    .Polaris-IndexTable__IndexTableWrapper
    table.Polaris-IndexTable__Table
    thead
    tr
    th:nth-last-child(1) {
    min-width: 120px;
  }

  .mob-pb-1 {
    padding-bottom: 1rem;
  }
}

@media (max-width: 47.9975em) {
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs)
    .Polaris-Page-Header__Row {
    display: flex;
  }
}

.page_spinner .Polaris-Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
}

.pre-product-list .Polaris-Spinner {
  display: flex;
  justify-content: center;
}

.pre_order_app_block img {
  width: 100%;
  border-radius: 3px;
}

.pre_order_app_block {
  border: 1px solid black;
  border-radius: 3px;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
  max-width: 61.25rem;
  width: 640px !important;
}
.onbpro_heading {
  text-align: right;
  font-weight: 600;
}
.db_pro .Polaris-DataTable .Polaris-DataTable__ScrollContainer table tbody tr {
  background-color: #f1f2f4;
}

.width-100p {
  width: 100%;
}

.Polaris-ProgressBar__Indicator {
  background-color: #303030 !important;
}
.tik-color {
  color: #1caa86;
}
.active-plan-is {
  color: 6D7175 !important;
  font-weight: 600 !important;
}

.accord-icon-rotate {
  rotate: 180deg;
}

.product-list-scroll {
  height: 250px;
  overflow-y: scroll;
}
.single-resource-item {
  border-bottom: 1px solid #dde0e4;
}
.single-resource-item:last-child {
  border-bottom: 0px;
}
.onboarding-resource-list-subitem > .Polaris-Box {
  padding-left: 60px !important;
}
.onboarding-resource-list-subitem li {
  margin-left: 40px !important;
}

.product-single-parent-list {
  padding: 12px 20px;
  border-bottom: 1px solid #dde0e4;
}
.product-single-child-list {
  padding: 12px 20px 12px 60px;
  border-bottom: 1px solid #dde0e4;
}
.product-single-parent-list:last-child {
  border-bottom: 0px;
}
.product-single-parent-list:hover {
  background-color: #f1f2f4;
}
.product-single-list-active {
  background-color: #f0f5fd;
}
.product-single-child-list:hover {
  background-color: #f1f2f4;
}
.crisp-client .cc-1brb6[data-full-view="true"] .cc-1yy0g .cc-1no03 {
  width: 320px !important;
}

/* back in stock css */
.back-in-stock-setting-div {
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
}
.back-in-stock-setting-div:hover {
  background-color: rgba(246, 246, 247, 1);
}
.h-16 {
  font-size: 16px;
  font-weight: 650;
  color: #303030;
}
.date-picker-card-box {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0rem 0.0625rem 0rem 0rem #1a1a1a12;
}
.rdt .Polaris-TextField__Input {
  border: 1px solid #8a8a8a;
  border-radius: 8px;
}

/* FEB 26 UMER ADDED CSS */

@media (max-width: 768px) {
  .react-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .react-tabs__tab-list {
    width: 100%;
  }
  .settings-page {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media (max-width: 974px) {
  .app-status-style{
    margin-bottom: 12px;
  }
}

.onb-card-style{
  border-radius: 12px;
  overflow: hidden;
  background-color: #FFFFFF;
  border: .5px solid #e3e3e3;
  box-shadow:rgba(26, 26, 26, 0.07) 0px 1px 0px 0px
}

.progress_bar {
  width: 100%;
}

.step_width {
  min-width: fit-content;
}

.pre-order-pro-reponsive
  .Polaris-IndexTable
  .Polaris-IndexTable__IndexTableWrapper
  table.Polaris-IndexTable__Table
  thead
  tr
  th:nth-child(2){
    top: 10px;
  }
  
  /* umer working from 9 march 2025 */

  .unchecked-selection-method {
    background-color: #F1F1F1;
    border-radius: 12px;
    width: 100%;
  }
  .checked-selection-method {
    background-color: #F1F1F1;
    border-radius: 12px;
    width: 100%;
    outline: 2px solid #303030;
  }
  
  .heading-size-16 {
    font-size: 16px;
    font-weight: 700;
  }
  
  .step-2-layout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 16px;
  }

  .method-rounded-card{
    background-color: white;
    border-radius: 12px;
    border: 1px solid #E3E3E3;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
  }
  
  .method-rounded-card .method-layout{
    display: flex;
    align-items: center;
  }

  .method-rounded-card .method-layout .Polaris-Checkbox{
    border-radius: 20px;
    overflow: hidden;
  }
   
  .step-3-layout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    gap: 16px;
    padding: 16px;
  }

  
.inactive-revenue-tabs {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
}
.active-revenue-tabs {
  background-color: #f3f3f3;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
}